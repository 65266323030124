import React from "react";
import { getVendeur } from "../HomePage/HomePage";

const PageNotAvailable = () => {
  const Vendeur = getVendeur();

  return (
    <div className="container-body-page-not-available">
      <div>
        <h1 className="title-page-not-available pb-3">
          Le site {Vendeur?.nom_de_la_boutique}
        </h1>
        <h3 className="text-page-not-available mt-3">
          est momentanément indisponible. <br /> Veuillez contacter
          l'administrateur.
        </h3>
      </div>
    </div>
  );
};

export default PageNotAvailable;
