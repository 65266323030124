import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import illustration from "../../../assets/images/appImages/illustration.png";
import founder1 from "../../../assets/images/appImages/founder_1.png";
import founder2 from "../../../assets/images/appImages/founder_2.png";
import {
  AiOutlineGoogle,
  AiOutlineTwitter,
  AiFillFacebook,
  AiFillLinkedin,
} from "react-icons/ai";
import { TeamData } from "./data";
import { Link } from "react-router-dom";
import FooterPage from "../../auth/FooterPage";

const CustomBeautyTitle = ({ title }: { title: string }) => {
  return <h2 className="custom-esb-title">{title}</h2>;
};

const TeamCard = ({
  photo,
  name,
  role,
  twitter,
  facebook,
  linkedin,
}: {
  photo?: string;
  name: string;
  role: string;
  twitter?: string;
  facebook?: string;
  linkedin?: string;
}) => {
  return (
    <div className="team-card">
      <span className="photo-wrapper">
        <img loading="lazy" src={photo} alt="photo" />
      </span>
      <span className="name">{name}</span>
      <span className="role">{role}</span>
      <div className="social-networks d-flex align-items-center justify-content-center gap-2">
        <Link target="_blank" to={`#`}>
          <AiOutlineGoogle />
        </Link>
        <Link target="_blank" to={`${twitter}`}>
          <AiOutlineTwitter />
        </Link>
        <Link target="_blank" to={`${facebook}`}>
          <AiFillFacebook />
        </Link>
        <Link target="_blank" to={`${linkedin}`}>
          <AiFillLinkedin />
        </Link>
      </div>
    </div>
  );
};

function WhoWeAre() {
  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content who-we-are-page pb-5 pb-5">
        <h1 className="custom-page-title">Qui sommes-nous ?</h1>
        <div className="who-we-are">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="illustration-container">
                  <img loading="lazy" src={illustration} alt="image" />
                </div>
              </div>
              <div className="col-md-6">
                <CustomBeautyTitle title="Qui sommes nous?" />
                <h3 className="custom-title">O'BEN</h3>
                <div className="custom-content">
                  Notre domaine de prédilection est le bien-être global, tant physique que mental. Nous mettons un point d'honneur
                  à ne travailler qu'avec des produits naturels. C'est pourquoi nous proposons des produits de beauté élaborés à partir de cosmétiques du
                  terroir, issus de la Côte d'Ivoire et du Mali. Nous offrons également des compléments alimentaires à base de plantes et de racines, afin
                  de promouvoir une santé naturelle et holistique.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: '4rem 0' }}></div>
      <FrontFooter />
    </div>
  );
}

export default WhoWeAre;
