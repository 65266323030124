import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Product, ProductFormData , CartFormData ,Cart,IVendeur } from "./product.type";

export const ProductApi = createApi({
  reducerPath: "productApi",
  tagTypes: [
    "product",
    "productList",
    "productByVisitor",
    "productBySlug",
    'productByVendeur',
    "allProductList",
    "productPromote",
    "download",
    "carts", 
    "cartByUser",
    "vendeurByApiKey",
    "productsEnAvant"
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getListProductAdmin: builder.query<PaginationResults<Product>, TypeQuery>({
      providesTags: ["productList"],
      query: (query) => QueryUrl("produits/for-admin/", query),
    }),
    getListProduct: builder.query<any, void>({
      providesTags: ["productList"],
      query: () => {
        return "visiteur/mobile/produits/";
      },
    }),
    getProductList: builder.query<PaginationResults<Product>, TypeQuery>({
      providesTags: ["productList"],
      query: (query) => QueryUrl("visiteur/produits/", query),
    }),
    getProductsEnAvant: builder.query<PaginationResults<Product>, TypeQuery>({
      providesTags: ["productsEnAvant"],
      query: (query) => QueryUrl("visiteur/produit/en_avants/", query),
    }),
    getSearchProductList: builder.query<PaginationResults<Product>, TypeQuery>({
      providesTags: ["productList"],
      query: (query) => QueryUrl("visiteur/produits/", query),
    }),
    getPromoteProductList: builder.query<PaginationResults<Product>, TypeQuery>(
      {
        providesTags: ["productPromote"],
        query: (query) => QueryUrl("article/promoted", query),
      }
    ),
    addOrEditProduct: builder.mutation<Product,{ id?: string; data: ProductFormData | FormData }>({
      onCacheEntryAdded(arg, { dispatch }) {
        setTimeout(() => {
          dispatch(
            ProductApi.util.invalidateTags([
              "productList",
              "productByVisitor",
              "product",
            ])
          );
        }, 1000);
      },
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `article/${id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `article/`,
          method: "POST",
          body: data,
        };
      },
    }),
    addOrEditPack: builder.mutation<Product,{ id?: string; data: ProductFormData | FormData }>({
      onCacheEntryAdded(arg, { dispatch }) {
        setTimeout(() => {
          dispatch(
            ProductApi.util.invalidateTags([
              "productList",
              "productByVisitor",
              "product",
            ])
          );
        }, 1000);
      },
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `article/${id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `article/create-pack/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteProduct: builder.mutation<Product, string>({
      query: (id) => ({
        url: `product/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["product", "productList", "productByVisitor"],
    }),
    productBySlug: builder.query<Product | any, string>({
      query: (slug) => `visiteur/produits/${slug}/`,
      providesTags: ["productBySlug"],
    }),
    productByVendeur: builder.query<Product | any, number>({
      query: () => `produits/`,
      providesTags: ["productByVendeur"],
    }),
    deleteProductImage: builder.mutation<Product,{ productId: string; pictureId: string }>({
      query: ({ productId, pictureId }) => ({
        url: `article/${productId}/pictures/${pictureId}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "product",
        "productList",
        "productByVisitor",
        "productBySlug",
      ],
    }),
    getModeleFile: builder.query<Product | any, void>({
      query: () => `article/download-model`,
      providesTags: ["download"],
    }),
    onImportProduct: builder.mutation<Product, FormData>({
      query: (data) => ({
        url: `article/import-excel`,
        method: "POST",
        body:data
      }),
      invalidatesTags: ["product", "productList", "productByVisitor"],
    }),
    getCartByUser: builder.query<Cart, string>({
			providesTags: ["cartByUser"],
			query: (slug) => `user/${slug}/cart/`,
		}),
    getVendeurByApiKey: builder.query<IVendeur | any, string>({
			providesTags: ["vendeurByApiKey"],
			query: (apiKey) => `vendeur/${apiKey}/`,
		}),
		addProductCart: builder.mutation<Product, CartFormData>({
			query: (data) => {
				return {
					url: `cart/add/`,
					method: "POST",
					body: data,
				};
			},
			invalidatesTags: ["carts", "cartByUser"],
		}),
    clearCart: builder.mutation<Product, string>({
			query: (slug) => ({
				url: `cart/${slug}/clear/`,
				method: "POST",
			}),
			invalidatesTags: ["carts", "cartByUser"],
		}),
    deleteProductCart: builder.mutation<Product, string>({
			query: (slug) => ({
				url: `cartitems/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["carts", "cartByUser"],
		}),
  }),
});

export const {
  useAddOrEditProductMutation,
  useGetListProductAdminQuery,
  useGetListProductQuery,
  useGetProductListQuery,
  useGetProductsEnAvantQuery,
  useDeleteProductMutation,
  useLazyProductBySlugQuery,
  useProductByVendeurQuery,
  useDeleteProductImageMutation,
  useAddOrEditPackMutation,
  useGetPromoteProductListQuery,
  useGetSearchProductListQuery,
  useLazyGetModeleFileQuery,
  useOnImportProductMutation,
  useGetCartByUserQuery,
  useAddProductCartMutation,
  useClearCartMutation,
  useDeleteProductCartMutation,
  useGetVendeurByApiKeyQuery,
} = ProductApi;

export function useProductFromLocation(): [
  Product,
  boolean,
  string,
  (id: string) => any
] {
  const itemState = useLocationState<Product | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findBySlug, { data, isError, isLoading }] = useLazyProductBySlugQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findBySlug(slug as string);
    }
  }, [slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      console.log("data", data);
      setItem(data);
    }
  }, [data]);

  return [item as Product, isLoading, slug as string, findBySlug];
}
