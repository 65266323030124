export const currentEnv: string = "prod";

const env: string = currentEnv;

export const Env = env;

const API_DEV_URL = "https://easy-market-api.withvolkeno.com";
const API_DEMO_URL = "https://easy-market-api.withvolkeno.com";
const API_PROD_URL = "https://api.easymarket.sn";

const VENDEUR_APIKEY_DEMO =
  "5b5669edcba16559929f585353f29202d36bc8c7d9ba00acdf1d42bed3c90c76";
const VENDEUR_APIKEY_PROD =
  "5b5669edcba16559929f585353f29202d36bc8c7d9ba00acdf1d42bed3c90c76";

export const APP_URL_DEV = "https://oben.volkeno-engineering.click/";
export const APP_URL_DEMO = "https://oben.volkeno-engineering.click/";
export const APP_URL_PROD = "https://oben.easymarket.sn/";

function processApiUrl() {
  if (env === "prod") return API_PROD_URL;
  if (env === "demo") return API_DEMO_URL;
  return API_DEV_URL;
}

function processAppUrl() {
  if (env === "prod") return APP_URL_PROD;
  if (env === "demo") return APP_URL_DEMO;
  return APP_URL_DEV;
}

function processApikey() {
  if (env === "prod") return VENDEUR_APIKEY_PROD;
  if (env === "demo") return VENDEUR_APIKEY_DEMO;
  return VENDEUR_APIKEY_DEMO;
}

export const ApiBaseUrl = processApiUrl();
export const AppBaseUrl = processAppUrl();

export const VendeurApikey = processApikey();

export const GoogleApikey = "AIzaSyD0klc1PQS8QK--Be3rF3i8kW_idy04AVQ";

export const IntechApikey = "554ACA96-F050-442D-8E29-BEE77FAE821A";

export const GoogleClientId =
  "701177325323-33rje48k1alj39qmb9vspat9urmmrojn.apps.googleusercontent.com";

