import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../../assets/images/appImages/logo.svg";
import { AiOutlineHeart, AiOutlineShoppingCart } from "react-icons/ai";
import { FiShoppingCart, FiUser, FiUsers } from "react-icons/fi";
import ConnexionModal from "./modal/ConnexionModal";
import Car from "../../../assets/images/icons/car.svg";
import CategoriesList from "./CategoriesList";
import SearchForm from "./SearchForm";
import { IoCallOutline } from "react-icons/io5";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { AppStorage } from "../../../utils/storage";
import { onSetPanier } from "../../../redux/slice/User.slice";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { getTotalAmount } from "../../../redux/slice/useCartSlice";
import { useGetCartByUserQuery } from "../../../utils/api/product/product.api";
import { MdInfoOutline, MdOutlineCategory } from "react-icons/md";
import {
  useGetProductListQuery,
  useGetListProductQuery,
} from "../../../utils/api/product/product.api";
import { useSearchParams, useLocation, useParams } from "react-router-dom";
import MobileCategoryModal from "../../user/ParcoursAchat/modal/MobileCategoryModal";
import { usePagination } from "../../../utils/hooks";

const FrontHeader = () => {
  const dispatch = useAppDispatch();
  const { user, token } = useAppSelector((s) => s?.user);
  const { cartItems } = useAppSelector((state) => state.cart);
  // const { data:carts } = useGetCartByUserQuery(user?.slug);
  const [modalConnexion, setModalConnexion] = useState(false);
  let [searchParams] = useSearchParams();
  const [searchCategory, setSearchCategory] = useState<any>();
  const [searchSousCategory, setSearchSousCategory] = useState<any>();
  const [query, setQuery] = React.useState({
    category: searchParams?.get("category") || "",
    min: searchParams?.get("min") || "",
    max: searchParams?.get("max") || "",
    orderBy: searchParams?.get("orderBy") || "",
  });

  const [value, setValue] = React.useState<any>({
    min: searchParams?.get("min") || 0,
    max: searchParams?.get("max") || 9000000,
  });
  let { state } = useLocation();

  useEffect(() => {
    dispatch(getTotalAmount());
  }, [cartItems]);

  const [show, setShow] = useState(false);

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 40) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <Navbar
        expand="lg"
        className="frontheader-component headering"
        id="navbar"
      >
        <div
          className={`second-header-custom no-view-mobile ${
            scrolled ? "scrolled" : ""
          }`}
        >
          <div className="content-left-second-header">
            <p className="text-second-header-custom">
              <FiUsers />
              <NavLink to={`/a-propos-de-nous`} className="btn no-link ">
                <span className="ps-2 text-second-header-custom">
                  Qui sommes nous ?
                </span>
              </NavLink>
            </p>
          </div>
          <div className="content-left-second-header">
            <img
              loading="lazy"
              src={Car}
              alt="Service"
              className="icon-svg-img"
            />
            <p className="text-second-header-custom">Livraison en 24h</p>
          </div>

          <div className="content-left-second-header">
            <span>
              <IoCallOutline />
            </span>
            <Link
              className="text-second-header-custom no-link"
              to="tel:+221 70 338 86 76"
            >
              +221 70 338 86 76
            </Link>
          </div>
        </div>
        <div
          className={`headering-second-level-header ${
            scrolled ? "scrolled" : ""
          }`}
        >
          <Navbar.Brand href="/">
            <img loading="lazy" src={Logo} alt="O'BEN" className="app-logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="navbar navbar-expand-lg navbar-light firstheader-costum nav-header-custom">
              <div className="w-100 header-navbar-contents-container">
                <div className="bloc-flex-info-user-header-content">
                  <div className="bloc-flex-info-user-header">
                    <div className="search-form-container no-view-mobile">
                      <SearchForm />
                    </div>
                    <div className="categorie-btn-container no-view-mobile">
                      <CategoriesList />
                    </div>
                  </div>
                </div>
                <div className="container-nav-content-nvlink p-2">
                  <NavLink to={`/produits`} className="btn nav-link menu-link">
                    <MdOutlineCategory size={22} />
                    <span className="ps-2">Produits</span>
                    {/* <button
                      onClick={() => setShow(true)}
                      className="btn nav-link menu-link d-flex align-items-center"
                    >
                      <MdOutlineCategory size={22} />
                      <span className="ps-2">Produits</span>
                    </button> */}
                  </NavLink>
                  <Nav.Link href="#home">
                    {!token ? (
                      <button
                        onClick={() => setModalConnexion(true)}
                        className="btn nav-link menu-link d-flex align-items-center"
                      >
                        <FiUser size={22} />
                        <span className="ps-2">Se Connecter</span>
                      </button>
                    ) : (
                      <NavLink
                        to={`/mon-compte/profil`}
                        className="btn nav-link menu-link"
                      >
                        <FiUser />
                        <span className="ps-2">Mon compte</span>
                      </NavLink>
                    )}

                    <ConnexionModal
                      modalConnexion={modalConnexion}
                      setModalConnexion={setModalConnexion}
                    />
                    <MobileCategoryModal
                      show={show}
                      setShow={setShow}
                      value={value}
                      setValue={setValue}
                      setSearchCategory={setSearchCategory}
                      setSearchSousCategory={setSearchSousCategory}
                      query={query}
                      setQuery={setQuery}
                      catgoryId={state?.catgoryId}
                    />
                  </Nav.Link>
                  {/* <NavLink
                    to={`/a-propos-de-nous`}
                    className="btn nav-link menu-link"
                  >
                    <MdInfoOutline size={22} />
                    <span className="ps-2">Qui sommes nous ?</span>
                  </NavLink> */}
                  {user ? (
                    <NavLink
                      to="/mon-compte/favoris"
                      className="btn nav-link menu-link"
                    >
                      <AiOutlineHeart />
                      <span className="ps-2">Mes favoris</span>
                    </NavLink>
                  ) : (
                    ""
                  )}
                  <NavLink
                    to="/panier"
                    className="btn nav-link menu-link position-relative no-view-mobile"
                  >
                    <FiShoppingCart style={{ fontSize: 15 }} />
                    <span className="ps-2">Panier</span>
                    <span className="counter-shop-notif">
                      {cartItems?.length}
                    </span>
                  </NavLink>
                </div>
              </div>
              <div className="second-header-custom no-view-desktop">
                <div className="content-left-second-header">
                  <p className="text-second-header-custom">
                    <FiUsers />
                    <NavLink to={`/a-propos-de-nous`} className="btn no-link ">
                      <span className="ps-2 text-second-header-custom">
                        Qui sommes nous ?
                      </span>
                    </NavLink>
                  </p>
                </div>
                <div className="content-left-second-header">
                  <img
                    loading="lazy"
                    src={Car}
                    alt="Service"
                    className="icon-svg-img"
                  />
                  <p className="text-second-header-custom">Livraison en 24h</p>
                </div>

                <div className="content-left-second-header">
                  <span>
                    <IoCallOutline />
                  </span>
                  <Link
                    className="text-second-header-custom no-link"
                    to="tel:+221 70 338 86 76"
                  >
                    +221 70 338 86 76
                  </Link>
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
          <div className="container-menu-search-bloc-and-cart no-view-desktop position-relative mt-3">
            <div className="search-form-container ">
              <SearchForm />
            </div>
            <NavLink
              to="/panier"
              className="btn nav-link menu-link position-relative"
            >
              <FiShoppingCart style={{ fontSize: 15 }} />
              <span className="counter-shop-notif">{cartItems?.length}</span>
            </NavLink>
          </div>
        </div>
      </Navbar>
    </div>
  );
};

export default FrontHeader;
